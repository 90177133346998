import React from "react"
import { Box, Heading } from "theme-ui"

import BlockContainer from "./block-container"
import BlockTitle from "./block-title"

const ContentfulServicesBlock = ({
  services,
}) => {
  return (
    <BlockContainer>
      <BlockTitle id="about" title="Services" />
      <Box mt={[4, 4, 5, 5]}>
        {services?.split("\n").map((service, index) => {
          return (
            <Heading as="h2" variant="h2" key={`heading-${index}`}>
              {service}
            </Heading>
          )
        })}
      </Box>
    </BlockContainer>
  )
}

export default ContentfulServicesBlock
