import _ from "lodash"
import React from "react"
import { Box, Flex } from "theme-ui"

import { globalDocument, globalWindow } from "../utils/dom"
import BlockContainer from "./block-container"
import MainHeroText from "./main-hero-text"

import backgroundImage from './background-image'

const ContentfulHeroBlock = ({
  headline,
  px = 0,
  py = 5,
}) => {
  let lines = headline.split("\n")
  let  multiplier = 1

  if (globalWindow.innerWidth < 1200) {
    multiplier = 0.7
  }

  if (globalWindow.innerWidth < 450) {
    lines = ["Your", "future", "is", "obvious."]
    multiplier = 0.5
  }

  return (
    <Box
      id="hero"
      onMouseMove={(e) => {
        const heroRef = globalDocument.querySelector("#hero")

        if (heroRef) {
          const x = Math.round(
            (e.clientX / globalWindow.innerWidth - 0.5) * -100 * 0.25 + 75,
          )
          const y = Math.round(
            (e.clientY / globalWindow.innerHeight - 0.5) * -100 * 0.5,
          )

          // @ts-ignore
          heroRef.style.backgroundPosition = `${x}% ${y}%`
        }
      }}
      sx={{
        backgroundImage: backgroundImage.base64,
        backgroundPosition: "50% 50%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "50%",
        transition: "500ms",
        width: "100%",
      }}
    >
      <BlockContainer noPaddings>
        <Flex
          sx={{
            flexDirection: "column",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            px,
            py,
            transform: [
              "translateY(-103px)",
              "translateY(-103px)",
              "none",
              "none",
            ],
          }}
        >
          {lines.map((line, index) => {
            let l = line.trim()
            return <MainHeroText 
              key={`hero-${index}`} 
              identifier={`hero-text-${index}`} 
              label={l} 
              multiplier={multiplier}
            />
          })}
        </Flex>
      </BlockContainer>
    </Box>
  )
}

export default ContentfulHeroBlock
