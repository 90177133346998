import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Box, Container, Flex, Heading, Text } from "theme-ui"

// @ts-ignore
import { globalWindow } from "../utils/dom"
import { displayDesktopOnly, displayMobileOnly } from "../utils/sx"
import BlockContainer from "./block-container"
import HorizontalScroller from "./horizontal-scroller"
import { InternalLink } from "./link"

const getSlideHeight = () => {
  return globalWindow.innerHeight
}

const getSlideWidth = () => {
  return globalWindow.innerWidth >= 832
    ? globalWindow.innerWidth - 300 - (globalWindow.innerWidth - 1280) / 2
    : globalWindow.innerWidth
}

const ContentfulProjectsBlock = ({
  projects,
}) => {
  const [slideHeight, setSlideHeight] = useState(0)
  const [slideWidth, setSlideWidth] = useState(0)

  useEffect(() => {
    setSlideHeight(getSlideHeight())
    setSlideWidth(getSlideWidth())

    const onResize = () => {
      setSlideHeight(getSlideHeight())
      setSlideWidth(getSlideWidth())
    }

    // @ts-ignore
    globalWindow.addEventListener("resize", onResize)

    return () => {
      // @ts-ignore
      globalWindow.removeEventListener("resize", onResize)
    }
  }, [])

  return (
    <Box>
      <BlockContainer noPaddings>
        <Heading as="h2" sx={{ pb: 3 }} variant="h2">
          This is how we roll:
        </Heading>
      </BlockContainer>
      <Flex
        sx={{
          bg: "white",
          flexDirection: "column",
          pl: `${globalWindow.innerWidth - slideWidth}px`,
        }}
      >
        <HorizontalScroller
          identifier="projects"
          onOffsetTop={(offsetTop) => {
            for (let i = 0; i < projects.length; i++) {
              const currentSlide = Math.round(offsetTop / getSlideWidth())
              const bodySelector = `#slide-${currentSlide}-body`
              const imgSelector = `#slide-${i}-img`
              if (document.querySelector(imgSelector)) {
                const scaleValue =
                  (offsetTop - getSlideWidth() * i) / 2 / getSlideWidth() + 1
                const transform = Math.max(scaleValue, 1)
                ;(document.querySelector(
                  imgSelector,
                )).style.transform = `scale(${transform})`
              }
              ;(document.querySelector(
                bodySelector,
              )).style.opacity = `1`
              ;(document.querySelector(
                bodySelector,
              )).style.transform = `translateY(0px)`
            }
          }}
          slideHeight={slideHeight}
          slideWidth={slideWidth}
          title="Projects"
          total={projects?.length || 0}
        >
          {projects.map((project, index) => {
            const view = (
              <Flex
                sx={{
                  alignItems: ["flex-start", "flex-start", "center", "center"],
                  flexDirection: [
                    "column-reverse",
                    "column-reverse",
                    "row",
                    "row",
                  ],
                  height: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <Flex
                  id={`slide-${index}-body`}
                  sx={{
                    alignItems: [
                      "flex-start",
                      "flex-start",
                      "flex-end",
                      "flex-end",
                    ],
                    flexDirection: "column",
                    mr: [0, 0, 5, 5],
                    opacity: [1, 1, 0, 0],
                    transform: ["translateY(0px)", "translateY(0px)", "translateY(100px)", "translateY(100px)"],
                    transition: "1s",
                  }}
                >
                  <Box sx={{ ...displayDesktopOnly }}>
                    <Heading
                      as="h1"
                      sx={{
                        paddingLeft: "24px",
                        textAlign: ["left", "left", "right", "right"],
                        whiteSpace: "pre-wrap",
                      }}
                      variant="h1"
                    >
                      {project?.previewHeading}
                    </Heading>
                    <Heading
                      as="h2"
                      sx={{
                        my: 3,
                        textAlign: ["left", "left", "right", "right"],
                      }}
                      variant="h2"
                    >
                      {project?.name}
                    </Heading>
                  </Box>
                  <Box sx={{ ...displayMobileOnly }}>
                    <Heading
                      as="h3"
                      sx={{
                        my: 3,
                        pb: 1,
                        textAlign: ["left", "left", "right", "right"],
                      }}
                      variant="h3"
                    >
                      {project?.name}
                    </Heading>
                  </Box>
                  {project?.previewTags?.map((tag, index) => {
                    return (
                      <Text
                        key={`tag-${index}`}
                        sx={{
                          mt: 1,
                          textAlign: ["left", "left", "right", "right"],
                        }}
                        variant="caption1"
                      >
                        + {tag}
                      </Text>
                    )
                  })}
                  <Flex
                    sx={{
                      ":hover": {
                        img: {
                          transform: "translateX(8px)",
                        },
                      },
                      alignItems: "center",
                      mt: 4,
                    }}
                  >
                    <img
                      alt=""
                      src="/arrow.svg"
                      style={{
                        marginRight: "24px",
                        transition: "200ms",
                        width: "32px",
                      }}
                    />
                    <Text variant="caption1">{project?.previewLinkLabel}</Text>
                  </Flex>
                </Flex>
                <Box
                  sx={{
                    bg: "body",
                    height: "100%",
                    maxHeight: ["35vh", "35vh", "100vh", "100vh"],
                    mb: [4, 4, 0, 0],
                    overflow: "hidden",
                    width: ["auto", "auto", "30vw", "30vw"],
                  }}
                >
                  <img
                    alt={project?.name}
                    id={`slide-${index}-img`}
                    src={project?.previewPicture?.resize?.src}
                    style={{
                      height: "100%",
                      objectFit: "cover",
                      // eslint-disable-next-line prettier/prettier
                      transform: "scale(1)",
                      width: "100%",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: ["block", "block", "none", "none"],
                    mb: 3,
                    mt: 1,
                  }}
                >
                  <Text sx={{ color: "placeholder" }} variant="caption2">
                    0{index + 1} / 0{projects?.length}
                  </Text>
                </Box>
              </Flex>
            )

            return (
              <InternalLink
                key={`internal-link-${index}`}
                href={project?.slug}
                style={{
                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{
                    display: "inline-block",
                    height: "100%",
                    maxWidth: slideWidth,
                    minWidth: slideWidth,
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      display: ["none", "none", "block", "block"],
                      height: "100%",
                    }}
                  >
                    {view}
                  </Box>
                  <Box
                    sx={{
                      display: ["block", "block", "none", "none"],
                      height: "100%",
                    }}
                  >
                    <Container style={{ height: "100%" }}>{view}</Container>
                  </Box>
                </Box>
              </InternalLink>
            )
          })}
        </HorizontalScroller>
      </Flex>
    </Box>
  )
}

export default ContentfulProjectsBlock
