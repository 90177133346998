import React, { useEffect, useState } from "react"
import VizSensor from "react-visibility-sensor"
import { Box } from "theme-ui"

const MainHeroText = ({
  animationStrengthFactor = 100,
  fontFamily = "Pilowlava Regular",
  fontSize = 96,
  identifier,
  label,
  multiplier = 1,
}) => {
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)

  const [_blotter, setBlotter] = useState()
  const [_material, setMaterial] = useState()

  let blotter
  let material
  let requestAnimationFrameId
  let text

  useEffect(() => {
    // @ts-ignore
    if (typeof Blotter !== "undefined") {
      // Create the blotter material.
      // @ts-ignore
      material = new Blotter.RollingDistortMaterial()
      setMaterial(material)
      // Set the default material uniform values.
      material.uniforms.uSineDistortSpread.value = 0.354
      material.uniforms.uSineDistortCycleCount.value = 5
      material.uniforms.uSineDistortAmplitude.value = 0
      material.uniforms.uNoiseDistortVolatility.value = 0
      material.uniforms.uNoiseDistortAmplitude.value = 0.168
      material.uniforms.uDistortPosition.value = [0.38, 0.68]
      material.uniforms.uRotation.value = 48
      material.uniforms.uSpeed.value = 0.421
      // Create the Blotter instance.
      // @ts-ignore
      blotter = new Blotter(material)
      setBlotter(blotter)
      const blotterElem = document.querySelector(`#${identifier}`)

      if (blotterElem) {
        // @ts-ignore
        text = new Blotter.Text(label, {
          family: fontFamily,
          size: fontSize,
        })
        blotter.addText(text)
        // The created canvas.
        const canvas = blotter.forText(text)
        // Append it to the main element.
        canvas.appendTo(blotterElem)
      }

      let latestScrollTop = 0
      const scrollRef = document.querySelector("html")

      const update = () => {
        if (scrollRef && scrollRef.scrollTop <= window.innerHeight) {
          const heroText = document.querySelector(`#${identifier}`)
          const heroTextMeasure = document.querySelector(
            `#${identifier}-measure`,
          )
          // Calculate line height on init.
          if (height === 0) {
            if (heroText && heroTextMeasure) {
              const aspectRatio =
                heroTextMeasure.clientWidth / heroTextMeasure.clientHeight
              setHeight(heroTextMeasure.clientHeight)
              setWidth(heroTextMeasure.clientWidth)
            }
          }
          // Set distortion animation based on the scroll velocity.
          const velocity =
            Math.abs(scrollRef.scrollTop - latestScrollTop) /
            animationStrengthFactor
          latestScrollTop = scrollRef.scrollTop
          material.uniforms.uSineDistortAmplitude.value = Math.max(
            0.0025,
            velocity,
          )
        }
        requestAnimationFrameId = window.requestAnimationFrame(update)
      }

      update()
    }

    return () => {
      window.cancelAnimationFrame(requestAnimationFrameId)

      blotter.removeTexts(blotter.texts)
      blotter.stop()
    }
  }, [])

  return (
    <VizSensor
      offset={{ top: -500 }}
      onChange={(isVisible) => {
        if (isVisible) {
          if (_blotter) {
            _blotter.start()
          }
        } else {
          if (_blotter && _material) {
            _material.uniforms.uSineDistortAmplitude.value = 0
            _blotter.stop()
          }
        }
      }}
    >
      <Box px={[3, 4, 4, 4]} py={0}>
        {/* Ghost text. */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            opacity: 0,
            position: "absolute",
            zIndex: -100,
          }}
        >
          <span id={`${identifier}-measure`} style={{ fontFamily }}>
            {label}
          </span>
        </div>
        {/* Real text. */}
        <div
          id={identifier}
          style={{
            fontFamily,
            fontSize: "120px",
            height: `${height * 6 * multiplier}px`,
            width: `${width * 5* multiplier}px`,
            opacity: height === 0 ? 0 : 1,
          }}
        />
      </Box>
    </VizSensor>
  )
}

export default MainHeroText
